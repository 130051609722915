<template>
  <div class="bfla-footer">
    <div class="bfla-footer__container container">
      <div class="bfla-footer__nav">
        <div class="bfla-footer__menu">
          <a
            v-for="itemMenu in menu"
            :key="itemMenu.href"
            :href="itemMenu.href"
            class="bfla-footer__menu-item"
          >
            {{ itemMenu.text }}
          </a>
        </div>
        <div class="bfla-footer__links">
          <div class="bfla-footer__social">
            <a
              v-for="itemSocial in socials"
              :key="itemSocial.icon"
              :href="itemSocial.href"
              target="_blank"
              class="bfla-footer__social-item"
            >
              <Icon :name="itemSocial.icon"></Icon>
            </a>
          </div>
          <LangSwitch
            class="bfla-footer__lang"
            :locales="themeSettings.languages"
            :locale="$lang"
            :dropdownOptions="{ triggerId: 'footerLangSwitch' }"
            hideCurrent
            @change="changeLang"
          >
            <template v-slot:default="{ language }">
              <Button
                id="footerLangSwitch"
                variant="ghost"
                view="light"
                size="sm"
                :text="language.text"
                icon-left="global"
              ></Button>
            </template>
          </LangSwitch>
        </div>
      </div>
      <div class="bfla-footer__copyright">
        <p class="bfla-footer__text">
          © {{ year }},
          <span v-html="$t('footer.copy')"></span>
          <span class="bfla-footer__separator">|</span>
          <a class="bfla-footer__link" href="mailto:runin@bfla.eu">runin@bfla.eu</a>
          <span class="bfla-footer__separator">|</span>
          <a class="bfla-footer__link" href="tel:+375293076829">+375 29 307 68 29</a>
        </p>
        <p class="bfla-footer__text" v-html="$t('footer.certificate')"></p>
      </div>
      <div class="bfla-footer__partners">
        <img src="../img/visa.svg" alt="visa">
        <img src="../img/visa-secure.svg" alt="visa secure">
        <img src="../img/mastercard.svg" alt="mastercard">
        <img src="../img/mastercard-securecode.svg" alt="mastercard securecode">
        <img src="../img/mir.svg" alt="mir">
        <img src="../img/mir-accept.png" alt="mir accept">
        <img src="../img/belkart-internet-password.svg" alt="belkart internet password">
        <img src="../img/belkart.svg" alt="belkart">
        <img src="../img/webpay.png" alt="webpay">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';
import ru from '../locales/ru.json';
import en from '../locales/en.json';

let themeUrls = null;

export default {
  name: 'BFLAFooter',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  components: {
    LangSwitch,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      menu: [
        {
          text: this.$t('footer.events'),
          href: `${themeUrls.Events}`,
        },
        {
          text: this.$t('footer.results'),
          href: `${themeUrls.Results}`,
        },
        {
          text: this.$t('footer.contacts'),
          href: `${themeUrls.Events}/Contacts`,
        },
        {
          text: this.$t('footer.payment'),
          href: `${themeUrls.Main}/Payment/Info`,
        },
      ],
      socials: [
        {
          icon: 'vkontakte',
          href: 'https://vk.com/bfla_best',
        },
        {
          icon: 'facebook',
          href: 'https://facebook.com/blr.athletics/',
        },
        {
          icon: 'twitter',
          href: 'https://twitter.com/athleticsblr',
        },
        {
          icon: 'instagram',
          href: 'https://www.instagram.com/runin.by/',
        },
        {
          icon: 'youtube',
          href: 'https://youtube.com/channel/UCe7b22ivuOW8uUJmYEeo-ew/videos',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('settings', ['themeSettings']),
  },
  methods: {
    changeLang(lang) {
      setCulture(lang, themeUrls.Main);
    },
  },
  beforeCreate() {
    themeUrls = this.$store.getters['settings/themeUrls'];
  },
};
</script>

<style lang="scss" scoped>
@import "./Footer";
</style>
